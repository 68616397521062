import React from "react";
import ReactDOM from "react-dom";

import firebase from "js/firebase";

//
// entry point
//

// firebase
firebase.initializeApp(window.baiFirebase.config);

// react
const root = document.createElement("div");
root.id = "react-root";
root.style.display = "contents";
document.body.appendChild(root);

// firebase dependent code
(async () => {
    // preload
    await import(/* webpackMode: "eager" */ "js/preload");

    const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
    const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
    Adapter.setDefaultClass(FirebaseAdapter);

    const { default: PPTAddin } = await import(/* webpackMode: "eager" */ "js/react/views/PPTAddin/PPTAddin");

    // render
    ReactDOM.render(<PPTAddin />, root);
})();

