import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Route, Router, Switch } from "react-router-dom";
import loadable from "@loadable/component";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { browserHistory } from "js/react/history";
import theme from "js/react/materialThemeOverrides";
import { AuthConsumer } from "js/react/views/Auth/AuthConsumer";
import { FirebaseAuthProvider } from "js/react/views/Auth/FirebaseAuthContext";
import Logout from "js/app/Logout";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import Api from "js/core/api";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

import { PPTAddinChoosePlanWithRouter } from "./PPTAddinChoosePlan";
import PPTAddinCommands from "./PPTAddinCommands";
import PPTAddinAuthenticate from "./PPTAddinAuthenticate";
import PPTAddinVideo from "./PPTAddinVideo";

import { VerifyEmailSuccess } from "../Onboarding/VerifyEmailReturn";

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const LoadablePPTAddinTaskpane = loadable(() => import(/* webpackChunkName: "pptAddinTaskpane" */ "js/react/views/PPTAddin/PPTAddinTaskpane"), {
    fallback: <FetchingClickShield backgroundColor="#000000" visible />
});

const LoadablePPTAddinEditor = loadable(() => import(/* webpackChunkName: "pptAddinEditor" */ "js/react/views/PPTAddin/PPTAddinEditor"), {
    fallback: <FetchingClickShield opaque visible />
});

const LoadableCheckout = loadable(() => import(/* webpackChunkName: "pptAddingCheckout" */ "js/app/Checkout"), {
    fallback: <FetchingClickShield opaque visible />
});

const Container = styled.div`
    background: #F8FAFC;
`;

function PPTAddin() {
    const [isReady, setReady] = useState(false);

    const [checkout, setCheckout] = useState({
        plan: null,
        enterpriseInviteId: null,
        billingInterval: null,
        priceId: null,
        displayName: null,
        quantity: null
    });

    useEffect(() => {
        if (location.pathname === "/ppt-addin/checkout") {
            const searchParams = new URLSearchParams(location.search);
            setCheckout({
                plan: searchParams.get("plan"),
                enterpriseInviteId: searchParams.get("enterpriseInviteId"),
                billingInterval: searchParams.get("billingTerm"),
                priceId: searchParams.get("price"),
                displayName: searchParams.get("displayName"),
                quantity: searchParams.get("quantity")
            });
        }
    }, [location.pathname, location.search]);

    useEffect(() => {
        Office.initialize = () => {};
        Office.onReady(() => setReady(true));
    }, [0]);

    if (!isReady) {
        return <div></div>;
    }

    return (
        <FirebaseAuthProvider>
            <AuthConsumer>
                {() => (
                    <MuiThemeProvider theme={theme}>
                        <Router history={browserHistory}>
                            <Switch>
                                <Route path="/ppt-addin/commands" render={() => <PPTAddinCommands />} />
                                <Route path="/ppt-addin/authenticate/:page" render={props => <PPTAddinAuthenticate {...props} />} />
                                <Route path="/ppt-addin/video" render={() => <PPTAddinVideo />} />
                                <Route path="/ppt-addin/logout" render={() => <Logout />} />
                                <Route path="/ppt-addin/checkout" render={() =>
                                    (<Container>
                                        <LoadableCheckout
                                            {...checkout}
                                            closeDialog={() => Api.customToken.get().then(async customTokenRes => {
                                                Office.context.ui.messageParent(JSON.stringify({
                                                    eventType: "close",
                                                    token: customTokenRes.token,
                                                    reload: true
                                                }));
                                            }).catch(() => {
                                                ShowWarningDialog({
                                                    message: "Failed to authenticate. Please try again.",
                                                    acceptCallback: () => {
                                                        Office.context.ui.messageParent(JSON.stringify({
                                                            eventType: "error"
                                                        }));
                                                    }
                                                });
                                            })}
                                        />
                                    </Container>)
                                } />
                                <Route path="/ppt-addin/choosePlan" render={() => <PPTAddinChoosePlanWithRouter emphasizePlan="pro"/>} />
                                <Route path="/ppt-addin/verified" render={() =>
                                    (<Container>
                                        <VerifyEmailSuccess nextStep={ () => {
                                            // TODO: Uncomment this when we want to redirect to choosePlan
                                            //window.location = "/ppt-addin/choosePlan";

                                            // TODO: Remove this when we want to redirect to choosePlan
                                            Api.customToken.get().then(async customTokenRes => {
                                                Office.context.ui.messageParent(JSON.stringify({
                                                    eventType: "close",
                                                    token: customTokenRes.token,
                                                    reload: true
                                                }));
                                            }).catch(() => {
                                                ShowWarningDialog({
                                                    message: "Failed to authenticate. Please try again.",
                                                    acceptCallback: () => {
                                                        Office.context.ui.messageParent(JSON.stringify({
                                                            eventType: "error"
                                                        }));
                                                    }
                                                });
                                            });
                                        }} />
                                    </Container>)
                                } />
                                {/* Heavy views are loaded asynchronously */}
                                <Route path="/ppt-addin/taskpane" render={() => <LoadablePPTAddinTaskpane />} />
                                <Route path="/ppt-addin/editor" render={() => <LoadablePPTAddinEditor />} />
                            </Switch>
                        </Router>
                    </MuiThemeProvider>
                )}
            </AuthConsumer>
        </FirebaseAuthProvider>
    );
}

export default PPTAddin;
