import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Authenticate } from "js/react/views/Auth/Authenticate";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import Api from "js/core/api";
import { ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";

const Container = styled.div`
    height: 100%;
    width: 100%;
    
    background-color: #f8fafc;
`;

function AuthenticatedHandler() {
    useEffect(() => {
        Api.customToken.get().then(customTokenRes => {
            Office.context.ui.messageParent(JSON.stringify({
                eventType: "close",
                token: customTokenRes.token
            }));
        }).catch(() => {
            ShowWarningDialog({
                message: "Failed to authenticate. Please try again.",
                acceptCallback: () => {
                    Office.context.ui.messageParent(JSON.stringify({
                        eventType: "error"
                    }));
                }
            });
        });
    }, [0]);

    return (
        <FetchingClickShield fetching={true} backgroundColor="#f8fafc" />
    );
}

function PPTAddinAuthenticate(props) {
    useEffect(() => {
        Office.context.ui.addHandlerAsync(
            Office.EventType.DialogParentMessageReceived,
            eventArgs => onTaskpaneMessageReceived(JSON.parse(eventArgs.message))
        );
    }, []);

    const onTaskpaneMessageReceived = message => {
        if (message.eventType === "verifiedEmail") {
            window.location = "/ppt-addin/verified";
        }
    };

    return (
        <Container>
            <Authenticate
                defaultPage={props.match.params.page ?? "signIn"} >
                <AuthenticatedHandler />
            </Authenticate>
        </Container>
    );
}

export default PPTAddinAuthenticate;
